import { useCallback, useEffect, useMemo, useState } from "react";
import { mutate } from "swr";
import { userAuctionItemFavoriteHandler } from "@/actions/user";
import toast from "react-hot-toast";
import useUserStore from "@/stores/useUserStore";
import { MD5 } from "crypto-js";
import useUserFavoritesStore from "@/stores/useUserFavoritesStore";
import useModalStore from "./useModalStore";

interface IUseFavorite {
    itemId: number;
    userId: number | undefined
}

const useAuctionItemFavorite = ({ itemId, userId }: IUseFavorite) => {
    const { setFetchKeys, fetchKeys } = useUserStore();
    const [isLoading, setIsLoading] = useState(false);
    const { loginModal } = useModalStore();

    const { userFavorites } = useUserFavoritesStore();

    const favoriteFetchKey = fetchKeys?.favorites || MD5(`userFavorites-${userId}`).toString();

    useEffect(() => {
        if (!userId) return;

        (async () => {
            await mutate(favoriteFetchKey);
            setIsLoading(false);
        })();
    }, [userId, favoriteFetchKey]);

    const hasFavorited = userFavorites && userFavorites?.some((favorite: any) => favorite === itemId) || false

    const toggleFavorite = useCallback(
        async (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation(); // < This stops the main div redirection

            if (!userId) {
                loginModal.setTitle("Logga in för att lägga till favoriter");
                loginModal.onOpen();
                return;
            }

            setIsLoading(true);

            const hasFavorited = userFavorites?.some((favorite: any) => favorite === itemId) || false

            fetch(`${process.env.NEXT_PUBLIC_SITE_URL}/api/user/favorites`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    itemId,
                    action: hasFavorited ? "remove" : "add",
                }),
            }).then((res) => res.json()).then(async (data) => {
                //console.log('data', data);
            }).catch((error) => {
                toast.error(error?.response?.data?.message || "Kunde inte lägga/ta bort favorit-rop, försök igen senare.");
            }).finally(async () => {

                const randomString = Math.random().toString(36).substring(7);

                // When favorite update, we need to update the fetch keys
                setFetchKeys({
                    favorites: MD5(`userFavorites-${randomString}-${userId}`).toString(),
                    getUserAuctionItems: MD5(`userAuctionItems-${randomString}-${userId}`).toString()
                });
            });


        },
        [userId, itemId, loginModal, userFavorites, hasFavorited]
    );

    return {
        hasFavorited: hasFavorited,
        toggleFavorite,
        isLoading: isLoading,
        isError: false
    };
};

export default useAuctionItemFavorite;
