import React, { useCallback, useEffect } from 'react'
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { parseDate, parseToDate } from '@/lib/utils';
import 'dayjs/locale/sv';

dayjs.extend(duration);
dayjs.locale('sv');

const useCountDownTimer = (serverTime: any, endTime: string, auctionItemId?: any) => {
    const calculateTimeRemaining = useCallback(() => {
        const endTimeStr = parseDate(endTime);

        const serverTimeObject = parseToDate(serverTime || new Date())
        const now = serverTimeObject.getTime();

        return endTimeStr - now;
    }, [endTime, serverTime]);

    const timeRemaining = calculateTimeRemaining();

    const formatTimeRemaining = (milliseconds: number) => {
        const time = dayjs.duration(milliseconds);

        const minutes = time.minutes();
        const seconds = time.seconds();

        const targetDate = parseToDate(endTime);
        const currentDate = parseToDate(serverTime);

        const isSameDay = targetDate.toDateString() === currentDate.toDateString();
        const isTomorrow = targetDate.toDateString() === parseToDate(currentDate.getTime() + 24 * 60 * 60 * 1000).toDateString();

        if (milliseconds <= 0) {
            return '0 minuter, 0 sekunder';
        }
        else if (time.asMilliseconds() <= 2 * 60 * 1000) {
            return `${minutes} minut${minutes !== 1 ? 'er' : ''}, ${seconds} sekund${seconds !== 1 ? 'er' : ''}`;
        } else if (time.asMilliseconds() <= 60 * 60 * 1000) {
            return `${minutes} minut${minutes !== 1 ? 'er' : ''}, ${seconds} sekund${seconds !== 1 ? 'er' : ''}`;
        } else if (isSameDay) {
            return `Idag ${targetDate.toLocaleString("sv-SE", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            })}`;
        } else if (isTomorrow) {
            return `Imorgon ${targetDate.toLocaleString("sv-SE", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            })}`;
        } else {
            return targetDate.toLocaleString("sv-SE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            });
        }
    };

    // If the time remaining is less than 2 minutes, we consider the auction to be ending
    const isEnding = timeRemaining <= (2 * 60) * 1000

    return {
        timeRemainingString: !serverTime ? "Hämtar datum .." : formatTimeRemaining(timeRemaining),
        isEnding: !serverTime ? false : isEnding
    };
}

export default useCountDownTimer