import { Auction, AuctionItem } from "@/types/auction";
import voca from "voca";
import { parseToDate } from "./utils";

export const auctionArchiveLinkPattern = "/avslutad-auktion/:year/:auctionTitle/:partAuctionId/:partTitle/:partId";
export const auctionLinkPattern = "/auktion/:auctionTitle/:partAuctionId/:partTitle/:partId";
export const auctionItemLinkPattern = "/auktion/rop/:itemTitle/:itemId";

export const getAuctionLink = (auction: Auction, forcePattern?: string) => {
    const linkType = forcePattern ? forcePattern : (auction.partStatus === "running" ? auctionLinkPattern : auctionArchiveLinkPattern);
    const year = parseToDate(auction.partAuctionStart)?.getFullYear() || "0000";

    return linkType
        .replace(':year', voca.slugify(String(year)))
        .replace(':auctionTitle', voca.slugify(auction.auctionTitle))
        .replace(':partAuctionId', voca.slugify(auction.partAuctionId.toString()))
        .replace(':partTitle', voca.slugify(auction.partTitle || "p"))
        .replace(':partId', voca.slugify(auction.partId.toString()));
};

export const getAuctionItemLink = (auctionItem: AuctionItem, urlRef: string | boolean = false) => {
    if (!auctionItem?.itemId) return "";

    const baseUrl = auctionItemLinkPattern
        .replace(':itemTitle', voca.slugify(auctionItem?.itemTitle))
        .replace(':itemId', voca.slugify(auctionItem?.itemId.toString()));

    return urlRef ? `${baseUrl}?ref=${btoa(String(urlRef))}` : baseUrl;
};

export const isLinkMatch = (link: string, params: any, pattern: string) => {
    let buildUrl = pattern;

    for (const key in params) {
        if (params[key] === undefined) return false; // Early return if key is missing

        // Decode the parameter value to handle escaped characters
        const decodedParam = decodeURIComponent(params[key].replace(/\/$/, ""));
        buildUrl = buildUrl.replace(`:${key}`, voca.slugify(decodedParam));
    }

    const normalizedBuildUrl = buildUrl.replace(/\/$/, "");
    const normalizedLink = decodeURIComponent(link).replace(/\/$/, "");

    return normalizedBuildUrl === normalizedLink;
};