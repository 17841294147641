import { formatPrice } from "@/lib/utils";
import useUserStore from "@/stores/useUserStore";
import { AuctionItem } from "@/types/auction";
import classNames from "classnames";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { useShallow } from "zustand/react/shallow";

interface CurrentBidProps {
  auctionItem: AuctionItem;
  bids: Bid[] | undefined;
  small?: boolean;
  showLeadingUser?: boolean;
}

const MotionDiv = lazy(() =>
  import("framer-motion").then((mod) => ({ default: mod.motion.div }))
);

const CurrentBid: React.FC<CurrentBidProps> = ({
  auctionItem,
  bids,
  showLeadingUser = false,
  small = false,
}) => {
  const { user } = useUserStore(useShallow((state) => state));
  const userId = user?.id;
  const openingPrice = auctionItem.itemMinBid;
  const hasBids = bids && bids.length > 0;
  const leadingBid = (hasBids && bids[0].historyBidValue) || false;
  const userHasBidded =
    hasBids && bids.some((bid) => bid.historyBidUserId === userId);
  const userIsLeading = hasBids && bids[0].historyBidUserId === userId;
  const itemWinningBidValue = auctionItem?.itemWinningBidValue;

  const [previousLeadingBid, setPreviousLeadingBid] = useState(leadingBid);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (!leadingBid) return;

    if (previousLeadingBid !== leadingBid) {
      if (previousLeadingBid) setAnimate(true);
      setPreviousLeadingBid(leadingBid);
    }
  }, [leadingBid]);

  const bidValue = hasBids
    ? bids[0].historyBidValue
    : itemWinningBidValue
    ? itemWinningBidValue
    : openingPrice;

  const bidText = formatPrice(bidValue);

  const bidUserNameClass = classNames(
    "my-3 text-16 font-light flex flex-row items-center text-toast-blue px-4 py-2 bg-gray-200 rounded-lg space-x-2",
    {
      "!bg-bid-leading text-white": userHasBidded && userIsLeading,
      "!text-xs": small,
      //"!bg-bid-overbid text-white": userHasBidded && !userIsLeading,
    }
  );

  return (
    <div className="flex flex-row flex-wrap items-center">
      <Suspense fallback={bidText}>
        <MotionDiv
          animate={animate ? { opacity: 1, scale: 1.1, fontWeight: 700 } : {}}
          transition={{ duration: 0.5 }}
          onAnimationComplete={() => setAnimate(false)}
          className="inline-block font-bold mr-4 break-words w-full"
        >
          {bidText}
        </MotionDiv>
      </Suspense>

      {showLeadingUser && hasBids && (
        <div className={bidUserNameClass}>
          <span>
            <svg
              width="14px"
              height="14px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 14V17M12 14C9.58104 14 7.56329 12.2822 7.10002 10M12 14C14.419 14 16.4367 12.2822 16.9 10M17 5H19.75C19.9823 5 20.0985 5 20.1951 5.01921C20.5918 5.09812 20.9019 5.40822 20.9808 5.80491C21 5.90151 21 6.01767 21 6.25C21 6.94698 21 7.29547 20.9424 7.58527C20.7056 8.77534 19.7753 9.70564 18.5853 9.94236C18.2955 10 17.947 10 17.25 10H17H16.9M7 5H4.25C4.01767 5 3.90151 5 3.80491 5.01921C3.40822 5.09812 3.09812 5.40822 3.01921 5.80491C3 5.90151 3 6.01767 3 6.25C3 6.94698 3 7.29547 3.05764 7.58527C3.29436 8.77534 4.22466 9.70564 5.41473 9.94236C5.70453 10 6.05302 10 6.75 10H7H7.10002M12 17C12.93 17 13.395 17 13.7765 17.1022C14.8117 17.3796 15.6204 18.1883 15.8978 19.2235C16 19.605 16 20.07 16 21H8C8 20.07 8 19.605 8.10222 19.2235C8.37962 18.1883 9.18827 17.3796 10.2235 17.1022C10.605 17 11.07 17 12 17ZM7.10002 10C7.03443 9.67689 7 9.34247 7 9V4.57143C7 4.03831 7 3.77176 7.09903 3.56612C7.19732 3.36201 7.36201 3.19732 7.56612 3.09903C7.77176 3 8.03831 3 8.57143 3H15.4286C15.9617 3 16.2282 3 16.4339 3.09903C16.638 3.19732 16.8027 3.36201 16.901 3.56612C17 3.77176 17 4.03831 17 4.57143V9C17 9.34247 16.9656 9.67689 16.9 10"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span className="font-bold" aria-label="Ledande budgivare">
            {bids[0].historyBidUsername || "Anonym"}
          </span>
        </div>
      )}
    </div>
  );
};

export default CurrentBid;
