"use client";

import classNames from "classnames";
import Image from "next/image";
import AutoBidButton from "../Elements/AutoBidButton";
import {
  forwardRef,
  use,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { placeBid, removeUserAutoBids } from "@/actions/bids";
import useUserStore from "@/stores/useUserStore";
import useModalStore from "@/hooks/useModalStore";
import toast from "react-hot-toast";
import { mutate } from "swr";
import Button from "../Elements/Button";
import { useShallow } from "zustand/react/shallow";
import BeatLoader from "react-spinners/BeatLoader";
import { NumberFormatBase } from "react-number-format";
import { getErrorCodeMessage, getStatusCodeMessage } from "@/lib/reporting";
import { renderToStaticMarkup } from "react-dom/server";
import ReactHtmlParser from "html-react-parser";
import { formatPrice } from "@/lib/utils";
import "react-tooltip/dist/react-tooltip.css";
import useBidStore from "@/stores/useBidStore";

interface AuctionBidFieldProps {
  hasEnded?: boolean;
  layout?: "horizontal" | "vertical";
  auctionItem: any;
  leadingAutoBid: any;
  isLeadingAutoBid: boolean;
  leadingBid: number;
}

const AuctionBidField = forwardRef<HTMLInputElement, AuctionBidFieldProps>(
  ({ hasEnded, leadingAutoBid, isLeadingAutoBid, auctionItem }, ref: any) => {
    const [hasMounted, setHasMounted] = useState(false);
    const [bidIsProcessing, setBidIsProcessing] = useState(false);
    const [isRemovingAutoBid, setIsRemovingAutoBid] = useState(false);
    const [bidErrors, setBidErrors] = useState<any>([]);
    const formRef = useRef<HTMLFormElement>(null);
    const { loginModal } = useModalStore();
    const { user } = useUserStore(useShallow((state) => state));
    const inputRef = useRef<HTMLInputElement>(null);
    const { setBidFetchKeySeed, setLastBidId } = useBidStore();

    // Expose focus method to parent
    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
      scrollIntoView: (options?: ScrollIntoViewOptions) => {
        if (inputRef.current) {
          inputRef.current.scrollIntoView(options);
        }
      },
    }));

    useEffect(() => {
      setHasMounted(true);
    }, []);

    const [bidOptions, setBidOptions] = useState([
      {
        type: "auto",
        label: "Lägg max-bud",
        selected: true,
      },
      {
        type: "normal",
        label: "Lägg bud direkt",
      },
    ]);

    const {
      trigger,
      control,
      register,
      handleSubmit,
      setError,
      setValue,
      clearErrors,
      reset,
      formState: { errors },
    } = useForm<FieldValues>({
      mode: "onSubmit",
      reValidateMode: "onSubmit",
      defaultValues: {
        bidValue: "",
      },
      shouldUseNativeValidation: false,
    });

    const bidType = useMemo(
      () => bidOptions.find((option) => option.selected)?.type || "",
      [bidOptions]
    );

    useEffect(() => {
      if (hasEnded) {
        setValue("bidValue", "");
      }
    }, [hasEnded]);

    const placeBidHandler: SubmitHandler<FieldValues> = async (
      data,
      e?: React.BaseSyntheticEvent
    ) => {
      e?.preventDefault();

      if (!user) {
        loginModal.setTitle("Logga in för att lägga bud");
        loginModal?.onOpen();
        return;
      }

      setBidErrors([]);
      setBidIsProcessing(true);

      const bidValue = parseInt(data?.bidValue);

      if (!bidType || !bidValue) {
        setError("bidValue", {
          type: "manual",
          message: "Välj ett budalternativ",
        });
        setBidIsProcessing(false);
        return;
      }

      toast.dismiss("place-bid");
      toast.loading("Lägger bud...", { duration: 0, id: "place-bid" });

      try {
        const response = await placeBid(bidValue, auctionItem.itemId, bidType);

        if (response?.result !== "success") {
          const errMessage = getErrorCodeMessage(
            response?.error?.code,
            response?.error?.error_details,
            "Kunde inte lägga bud. Försök igen senare. (3)"
          );

          toast.dismiss("place-bid");
          // toast.error(
          //   <div>
          //     <span
          //       dangerouslySetInnerHTML={{
          //         __html: getErrorCodeMessage(
          //           response?.error?.code,
          //           response?.error?.error_details,
          //           "Kunde inte lägga bud. Försök igen senare. (3)"
          //         ),
          //       }}
          //     ></span>
          //   </div>,
          //   { duration: 10000, id: "place-bid" }
          // );
          setBidErrors((prevErrors: any[]) => [
            ...prevErrors,
            { message: errMessage },
          ]);

          return;
        } else if (!response?.result) {
          throw new Error("Invalid status response from server.");
        }

        toast.dismiss("place-bid");

        const success_status_code = response?.data?.code;

        if (success_status_code) {
          toast(
            getStatusCodeMessage(
              success_status_code.toString(),
              "Kunde inte lägga bud. Försök igen senare. (2)"
            ),
            {
              duration: 2000,
              id: "place-bid",
            }
          );

          const latestBidId =
            response?.data?.bids_data?.bids[0]?.historyBidItemId || 0;

          // Update these values only once
          const seed = Math.random().toString(36).substring(7);
          setLastBidId(latestBidId);
          setBidFetchKeySeed(seed);
        } else {
          throw new Error("Invalid status response from server.");
        }

        reset();
      } catch (error: any) {
        reset({ keepValues: true });
        toast.dismiss("place-bid");
        toast.error("Kunde inte lägga bud. Försök igen senare. (1)");
      } finally {
        setBidIsProcessing(false);
      }
    };

    const wraperClass = classNames(
      "flex flex-col flex-wrap items-start justify-start gap-[12px] text-16 text-gray-light w-full"
    );

    const inputClass = classNames(
      "bid-field !text-16 text-black font-semibold !outline-none disabled:text-gray-light-muted placeholder:text-gray-light-muted disabled:!bg-gray-ultra-light p-5 md:py-3 md:px-4 w-full rounded-lg",
      {
        "!bg-gray-ultra-light": hasEnded,
      }
    );

    const clearBidErrors = () => {
      setBidErrors([]);
    };

    const triggerFormSubmit = () => {
      formRef.current?.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
    };

    const groupedErrors = [
      ...bidErrors,
      ...Object.values(errors).map((error) => ({
        message: error?.message?.toString(),
      })),
    ];

    const removeAutoBidHandler = async () => {
      setIsRemovingAutoBid(true);

      try {
        const response = await removeUserAutoBids(auctionItem?.itemId);

        await mutate([`/api/getItemBids`, auctionItem.itemId]);
        await mutate(
          ["/api/getAuctionItemBids", auctionItem.itemPartId],
          undefined,
          {
            revalidate: true,
          }
        );

        if (!response) {
          throw new Error("Error removing autobid");
        }
      } catch (error: any) {
        toast.error("Kunde inte ta bort max-bud. Försök igen senare.");
      } finally {
        setIsRemovingAutoBid(false);
      }
    };

    const bidFormRules = user
      ? {
          required: "Ange ett bud",
          pattern: {
            value: /^[0-9.,]*$/,
            message: "Ange ett giltigt bud.",
          },
        }
      : {};

    const bidInputFormat = (numStr: any) => {
      if (numStr === "") return "";
      return new Intl.NumberFormat("sv-SE", {
        style: "currency",
        currency: "SEK",
        currencyDisplay: "symbol",
        maximumFractionDigits: 0,
      })
        .formatToParts(numStr)
        .map(({ type, value }) => {
          switch (type) {
            case "currency":
              return ` ${value}`;
            default:
              return value;
          }
        })
        .join("");
    };

    return (
      <form
        className={wraperClass}
        ref={formRef}
        onSubmit={handleSubmit(placeBidHandler)}
      >
        <>
          <Controller
            render={({ field: { onChange, value, ref } }: any) => (
              <NumberFormatBase
                type="text"
                placeholder="Ditt nästa bud ..."
                format={bidInputFormat}
                className={inputClass}
                onValueChange={(v) => onChange(v.value)}
                onKeyUp={clearBidErrors}
                value={value}
                disabled={hasEnded}
                getInputRef={inputRef}
              />
            )}
            rules={bidFormRules}
            name="bidValue"
            control={control}
          />

          {groupedErrors && groupedErrors?.length > 0 && (
            <span className="block w-full rounded-lg text-dark-red border-dark-red border border-solid px-5 py-3 text-base font-normal !my-1">
              {ReactHtmlParser(groupedErrors[0].message?.toString())}
            </span>
          )}
          <div className="flex flex-row flex-wrap items-center justify-start gap-[4px] md:space-x-2 w-full sm:w-auto">
            <AutoBidButton
              hasEnded={hasEnded}
              setBidOptions={setBidOptions}
              onButtonClick={() => triggerFormSubmit()}
              bidOptions={bidOptions}
              isLoading={bidIsProcessing}
            />
            <Image
              className="hidden md:block relative w-6 h-6 overflow-hidden shrink-0 object-cover opacity-[0.7] cursor-help"
              alt=""
              width="20"
              height="20"
              src="/icons/help.svg"
              data-tooltip-id="light-tooltip"
              data-tooltip-html={renderToStaticMarkup(
                <span className="flex flex-col space-y-3">
                  <span>
                    <span className="block text-16 font-semibold">
                      Max-bud (Autobud)
                    </span>
                    <span className="block text-14 font-normal">
                      Systemet lägger bud automatiskt upp till den summa ni
                      fyllt i. Så länge budgivningen inte nått upp till den nivå
                      du angivit som max så kan du avbryta maxbudet men de bud
                      som hunnit läggas kan inte ångras.
                    </span>
                  </span>

                  <span>
                    <span className="block text-16 font-semibold">
                      Direktbud
                    </span>

                    <span className="block text-14 font-normal">
                      Den summa ni fyllt i rutan läggs direkt. Var uppmärksam på
                      det belopp du skriver då detta bud inte går att ångra.
                    </span>
                  </span>
                </span>
              )}
            />
          </div>
        </>

        {hasMounted && isLeadingAutoBid && (
          <span className="flex flex-col space-y-3 w-fit">
            {isRemovingAutoBid ? (
              <BeatLoader color="#183e4f" />
            ) : hasEnded ? (
              <></>
            ) : (
              <>
                <div className="py-2 font-bold text-dark-gray-muted text-lg font-semibold rounded-lg w-fit">
                  Ditt nuvarande max-bud:{" "}
                  {formatPrice(leadingAutoBid?.autoMaxBid)}
                </div>

                <div className="flex flex-row space-x-4 items-center">
                  <>
                    {" "}
                    <Button
                      bgColor="transparent"
                      textColor="blue"
                      borderColor="blue"
                      onClick={removeAutoBidHandler}
                    >
                      Ta bort max-bud
                    </Button>
                    <Image
                      className="relative w-6 h-6 overflow-hidden shrink-0 object-cover opacity-[0.7] cursor-help"
                      alt=""
                      width="20"
                      height="20"
                      src="/icons/help.svg"
                      data-tooltip-id="light-tooltip"
                      data-tooltip-html={renderToStaticMarkup(
                        <span className="flex flex-col space-y-3">
                          <span>
                            <span className="block text-16 font-semibold">
                              Max-bud (Autobud)
                            </span>
                            <span className="block text-14 font-normal">
                              Systemet lägger bud automatiskt upp till den summa
                              ni fyllt i. Så länge budgivningen inte nått upp
                              till den nivå du angivit som max så kan du avbryta
                              maxbudet men de bud som hunnit läggas kan inte
                              ångras.
                            </span>
                          </span>

                          <span>
                            <span className="block text-16 font-semibold">
                              Direktbud
                            </span>

                            <span className="block text-14 font-normal">
                              Den summa ni fyllt i rutan läggs direkt. Var
                              uppmärksam på det belopp du skriver då detta bud
                              inte går att ångra.
                            </span>
                          </span>
                        </span>
                      )}
                    />
                  </>
                </div>
              </>
            )}
          </span>
        )}
      </form>
    );
  }
);

AuctionBidField.displayName = "AuctionBidField";

export default AuctionBidField;
