import classNames from "classnames";
import React, { useEffect } from "react";
import { Oval } from "react-loader-spinner";
import { StarChecked, StarUnchecked } from "@/lib/temp";

const FavoriteButton: React.FC<{ auctionItemFavorite: any }> = ({
  auctionItemFavorite,
}) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const [hydrated, setHydrated] = React.useState(false);

  const { hasFavorited, toggleFavorite, isLoading, isError } =
    auctionItemFavorite;

  useEffect(() => {
    setHydrated(true);
  }, []);

  const favoriteClassWrapper = classNames(
    "absolute top bg-white w-fit m-4 right-0 p-2 z-[200] rounded-md cursor-pointer",
    {
      "opacity-80 cursor-wait": hydrated && isLoading,
      "text-yellow hover:!text-yellow-darker": hasFavorited,
      "text-blue hover:!text-blue": !hasFavorited,
    }
  );

  const tooltipContent = hasFavorited ? "Ta bort favorit" : "Lägg till favorit";

  return (
    <>
      <div
        className={favoriteClassWrapper}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={(e) => (!isLoading ? toggleFavorite(e) : null)}
        data-tooltip-id="default-tooltip"
        data-tooltip-content={tooltipContent}
      >
        {!hydrated || isLoading ? (
          <Oval
            visible={true}
            height={25}
            width={25}
            color="#1c354d"
            secondaryColor="#e8eff5"
            strokeWidth={4}
            ariaLabel="oval-loading"
            wrapperStyle={{}}
          />
        ) : (
          <>
            {isHovering || hasFavorited ? <StarChecked /> : <StarUnchecked />}
          </>
        )}
      </div>
    </>
  );
};

export default FavoriteButton;
