import React from "react";

interface UsernameProps {
  name: string;
}

const MAX_LENGTH = 20;

const Username: React.FC<UsernameProps> = ({ name }) => {
  const nameParts =
    name.length > MAX_LENGTH ? name.slice(0, MAX_LENGTH - 3) + "..." : name;

  return <div>{nameParts}</div>;
};

export default Username;
