import classNames from "classnames";
import Image from "next/image";
import React from "react";

const NoImageFound = ({
  text = "Ingen bild hittades.",
  containerClass = "",
  useIcon = false,
}) => {
  const containerClassProps = classNames(
    "w-full h-full bg-blue select-none flex text-center items-center justify-center",
    {
      [containerClass]: containerClass,
    }
  );
  return (
    <div className={containerClassProps}>
      {text && <h3 className="text-white text-xl p-5">{text}</h3>}
      {useIcon && (
        <Image
          src="/icons/hammer-small.svg"
          alt="Hammer Icon"
          width="20"
          height="20"
        />
      )}
    </div>
  );
};

export default NoImageFound;
