import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Username from "./Username";
import Image from "next/image";
import { Tooltip } from "react-tooltip";
import ReactDOM from "react-dom";
import { formatPrice, parseToDate } from "@/lib/utils";

interface BidItemProps {
  userId: number;
  bid: Bid;
  allBids: any;
}

const BidItem: React.FC<BidItemProps> = ({ userId, bid, allBids }) => {
  const bidUserId = bid.historyBidUserId;
  const username = bid.historyBidUsername || "Anonym";
  const bidDate = bid.historyBidCreated;
  const isAutoBidded = bid.historyBidAutoTrigger;
  const isAutoBid = bid.historyBidType == "auto";
  const bidValue = formatPrice(bid?.historyBidValue);

  const userBids = allBids
    .filter((b: any) => b.historyBidUserId === userId)
    .sort(
      (a: any, b: any) =>
        parseToDate(b.historyBidCreated).getTime() -
        parseToDate(a.historyBidCreated).getTime()
    );

  const isLastPlacedBidForUser =
    userBids.length > 0 && userBids[0].historyBidId === bid.historyBidId;

  const bidClass = classNames(
    "text-12 border-transparent border-b-8 border-solid bg-gray-ultra-light",
    { "!bg-palegoldenrod": isLastPlacedBidForUser },
    { "opacity-50": isAutoBidded }
  );

  const dateOptions: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
  };

  const formattedDate = parseToDate(bidDate).toLocaleDateString(
    "sv-SE",
    dateOptions
  );

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedTime = parseToDate(bidDate).toLocaleTimeString(
    "sv-SE",
    timeOptions
  );

  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  const itemClasses =
    "flex-1 relative tracking-[-0.01em] leading-[150%] font-semibold py-2 px-2";

  return (
    <tr className={bidClass}>
      <td
        className={itemClasses}
        {...(isAutoBidded && {
          "data-tooltip-id": "default-tooltip",
          "data-tooltip-content":
            "Detta bud har lagts av ett max-bud som automatiskt kan bjuda över",
        })}
      >
        <span className="flex flex-row items-center space-x-1">
          {!!isAutoBid && (
            <Image
              src="/icons/auto-start.svg"
              alt="Auto-bud"
              width={20}
              height={20}
            />
          )}
          <Username name={username} />
        </span>
      </td>
      <td className={itemClasses}>{formattedDateTime}</td>
      <td className={itemClasses}>{bidValue}</td>
    </tr>
  );
};

export default BidItem;
