"use client";

import { create } from "zustand";
import { produce } from "immer";

interface AuctionBidState {
    bidFetchKeySeed: string;
    lastBidId: number;
    setBidFetchKeySeed: (newSeed: string) => void;
    setLastBidId: (newId: number) => void;
    resetBidFetchSeed: () => void;
}

const useBidStore = create<AuctionBidState>((set) => ({
    lastBidId: 0,
    bidFetchKeySeed: "",
    setLastBidId: (newId) =>
        set(
            produce((state) => {
                state.lastBidId = newId;
            })
        ),
    setBidFetchKeySeed: (newSeed) =>
        set(
            produce((state) => {
                state.bidFetchKeySeed = newSeed;
            })
        ),
    resetBidFetchSeed: () =>
        set(
            produce((state) => {
                state.bidFetchKeySeed = "";
            })
        ),
}));

export default useBidStore;