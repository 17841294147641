import { parseToDate } from "./utils";

export const CalendarSvg = () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-[12px] md:w-[16px]"
  >
    <path
      d="M3 10H21M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MarkerSvg = () => (
  <svg
    version="1.1"
    id="_x32_"
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    viewBox="0 0 512 512"
    className="w-[12px] md:w-[16px]"
  >
    <g>
      <path
        d="M256.016,0C158.797,0.031,80.094,78.781,80.063,175.953c0.063,14.297,3.031,28.641,7.563,43.797
  c7.969,26.438,21.094,55.328,36.281,84.547c45.563,87.359,110.328,177.391,110.688,177.891L256.016,512l21.391-29.813
  c0.25-0.313,37.969-52.844,76.016-116.266c19.016-31.766,38.141-66.25,52.828-98.859c7.344-16.313,13.578-32.172,18.156-47.313
  c4.531-15.156,7.469-29.5,7.531-43.797C431.906,78.781,353.203,0.031,256.016,0z M373.938,204.594
  c-6.344,21.156-18.25,47.906-32.594,75.359c-21.484,41.266-48.281,84.375-69.625,116.953c-5.719,8.719-10.969,16.609-15.703,23.594
  c-14.891-22-35.594-53.594-55.844-87.75c-17.719-29.906-35.063-61.75-47.656-90.25c-6.297-14.188-11.391-27.547-14.781-39.094
  c-3.422-11.5-5-21.281-4.953-27.453c0.016-34.109,13.75-64.734,36.078-87.156c22.391-22.328,53.016-36.063,87.156-36.094
  c34.109,0.031,64.75,13.766,87.125,36.094c22.359,22.422,36.078,53.047,36.094,87.156
  C379.281,182.344,377.594,192.563,373.938,204.594z"
      />
      <path
        d="M256.016,118.719c-31.594,0-57.219,25.641-57.219,57.234c0,31.609,25.625,57.219,57.219,57.219
  c31.578,0,57.219-25.609,57.219-57.219C313.234,144.359,287.594,118.719,256.016,118.719z"
      />
    </g>
  </svg>
);

export const StarChecked = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1501_746"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect x="1" y="1" width="22" height="22" fill="#D9D9D9" stroke="black" />
    </mask>
    <g mask="url(#mask0_1501_746)">
      <path
        d="M5.825 22L7.45 14.975L2 10.25L9.2 9.625L12 3L14.8 9.625L22 10.25L16.55 14.975L18.175 22L12 18.275L5.825 22Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const StarUnchecked = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_106_2642"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        fill="currentColor"
        stroke="black"
        strokeWidth="2"
      />
    </mask>
    <g mask="url(#mask0_106_2642)">
      <path
        d="M6.5784 20.9616L7.93714 15.0877L8.00498 14.7944L7.77753 14.5972L3.22002 10.646L9.24324 10.1231L9.5433 10.0971L9.66056 9.81965L12 4.28436L14.3394 9.81965L14.4567 10.0971L14.7568 10.1231L20.78 10.646L16.2225 14.5972L15.995 14.7944L16.0629 15.0877L17.4216 20.9616L12.2583 17.8469L12 17.6911L11.7417 17.8469L6.5784 20.9616Z"
        stroke="currentColor"
      />
    </g>
  </svg>
);

export const formatEndDate = (dateString: any) => {
  if (!dateString) return "";

  const date = parseToDate(dateString);
  if (isNaN(date.getTime())) return ""; // Handle invalid dates

  // Format as YYYY-MM-DD
  return date.toISOString().split("T")[0];
};
