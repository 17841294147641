import classNames from "classnames";
import React, { useState } from "react";

interface AutoBidButtonProps {
  hasEnded?: boolean;
  setBidOptions: React.Dispatch<any>;
  bidOptions: { type: string; label: string; selected?: boolean }[];
  onButtonClick?: () => void;
  isLoading?: boolean;
}

const AutoBidButton: React.FC<AutoBidButtonProps> = ({
  hasEnded,
  setBidOptions,
  bidOptions,
  isLoading,
  onButtonClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const buttonText = bidOptions.find((option) => option.selected)?.label;

  const buttonInnerClass = classNames(
    "rounded-tl-lg flex-grow whitespace-nowrap rounded-tr-none rounded-br-none rounded-bl-lg cursor-pointer bg-blue flex flex-row items-sretch justify-center py-3 md:py-2 px-[18px] transition-all duration-500",
    {
      "bg-blue hover:bg-blue/80": !hasEnded,
      "pointer-events-none !bg-aliceblue-dark/80 text-white/60 hover:bg-aliceblue-dark/80 text-white/60":
        hasEnded,
      "pointer-events-none opacity-80": isLoading,
    }
  );

  const buttonArrowClass = classNames(
    "rounded-tr-lg rounded-br-lg bg-blue-light cursor-pointer flex items-center justify-center py-2 px-2 duration-500 transition-all",
    {
      "bg-blue hover:bg-blue/80": !hasEnded,
      "pointer-events-none !bg-aliceblue-dark/80 text-white/60 hover:bg-aliceblue-dark/80 text-white/60":
        hasEnded,
      "pointer-events-none opacity-80": isLoading,
    }
  );

  return (
    <div className="flex flex-row items-stretch justify-start w-full md:w-auto text-white group relative select-none">
      <div
        className={`${
          !isExpanded ? "hidden" : ""
        } bg-white absolute top-[100%] right-0 rounded-lg z-10 transition-all duration-750 text-blue hover:bg-blue hover:text-white w-auto shadow-lg`}
      >
        {bidOptions
          .filter((e) => !e.selected)
          .map((option) => (
            <div
              key={option.type}
              className={classNames(
                "py-2 px-3 text-16 font-semibold cursor-pointer "
              )}
              onClick={() => {
                setBidOptions((prev: any) =>
                  prev.map((prevOption: any) => ({
                    ...prevOption,
                    selected: prevOption.type === option.type,
                  }))
                );

                setIsExpanded(false);
              }}
            >
              {option.label}
            </div>
          ))}
      </div>
      <div
        className={buttonInnerClass}
        onClick={() => onButtonClick && onButtonClick()}
      >
        <div className="relative tracking-[-0.01em] leading-[150%] font-semibold text-16">
          {buttonText}
        </div>
      </div>
      <div
        className={buttonArrowClass}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        {isLoading ? (
          <svg
            className="animate-spin mx-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={isExpanded ? "transform rotate-180" : ""}
          >
            <path
              d="M16.59 8.59003L12 13.17L7.41 8.59003L6 10L12 16L18 10L16.59 8.59003Z"
              fill="white"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default AutoBidButton;
