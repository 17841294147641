import React from "react";
import BidItem from "./BidItem";
import classNames from "classnames";
import { AuctionItem } from "@/types/auction";
import "react-loading-skeleton/dist/skeleton.css";

interface CurrentBidProps {
  auctionItem: AuctionItem;
  bidData: any;
  bidIsLoading: boolean;
  showAll?: boolean;
  titleClass?: string;
  noMaxHeight?: boolean;
  setSHowAllBids?: any;
  user?: any;
  hasEnded?: boolean;
  extraClass?: string;
  showAllBids?: boolean;
  maxBidsDisplayed?: number;
  showMorePosition?: "content" | "title";
}

const MAX_BIDS_DISPLAYED = 3;

const AuctionBidHistory: React.FC<CurrentBidProps> = ({
  auctionItem,
  bidData,
  bidIsLoading,
  titleClass,
  noMaxHeight,
  extraClass = false,
  setSHowAllBids = () => {},
  maxBidsDisplayed = MAX_BIDS_DISPLAYED,
  showAllBids = false,
  user,
  showAll = false,
  showMorePosition = "content",
}) => {
  const totalBidHits = bidData?.totalHits;

  let allBids =
    showAllBids || showAll
      ? bidData?.bids
      : bidData?.bids?.slice(0, maxBidsDisplayed);

  const isAuctionInactive = false;

  const bidBoxClass = classNames(
    "relative w-full flex flex-col items-start justify-start space-y-2 overflow-y-auto overflox-x-hidden h-[160px]",
    {
      "md:h-[400px]": !noMaxHeight && !showAll && !showAllBids,
      "h-[350px]": !noMaxHeight && (showAll || showAllBids),
      [`${extraClass}`]: extraClass,
    }
  );

  const canShowMore =
    !showAll && !showAllBids && allBids && allBids?.length < totalBidHits;

  const showMoreBidsLink = (
    <div className="relative flex lg:justify-center text-center overflow-x-hidden">
      {!showAll &&
        !showAllBids &&
        allBids &&
        allBids?.length < totalBidHits && (
          <div className="self-stretch relative leading-[150%] font-normal normal-case mx-auto">
            <div
              onClick={() => setSHowAllBids(true)}
              className="!no-underline text-16 block w-fit text-blue hover:text-blue/80 transition-all duration-200 font-semibold cursor-pointer  mx-auto"
            >
              Visa alla {totalBidHits} bud
            </div>
          </div>
        )}

      {!showAll && showAllBids && (
        <div className="self-stretch relative leading-[150%] font-normal">
          <div
            onClick={() => setSHowAllBids(false)}
            className="!no-underline text-16 block w-fit text-blue font-semibold cursor-pointer mx-auto"
          >
            Visa mindre
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-black">
        <div
          className={classNames(
            "flex flex-row md:flex-col lg:flex-row mb-1 items-left lg:items-center md:justify-start lg:justify-between self-stretch relative text-base leading-[150%] uppercase font-semibold",
            { [`${titleClass}`]: titleClass }
          )}
        >
          <span>Budhistorik</span>{" "}
          {showMorePosition === "title" &&
            !isAuctionInactive &&
            showMoreBidsLink}
        </div>

        {isAuctionInactive ? (
          <div className="self-stretch relative leading-[150%] font-normal">
            Auktionen är avslutad och
          </div>
        ) : (
          <div className="w-full">
            {!allBids ||
            !allBids.length ||
            (allBids && allBids?.length == 0 && bidIsLoading) ? (
              <div>
                <div className="self-stretch relative leading-[150%] font-normal">
                  Inga bud
                </div>
              </div>
            ) : (
              <>
                <div className={bidBoxClass}>
                  <table className="table-auto border-collapse w-full">
                    <tbody>
                      {allBids &&
                        allBids.map((bid: any, index: number) => (
                          <BidItem
                            key={index}
                            userId={user?.id || 0}
                            allBids={allBids}
                            bid={bid}
                          />
                        ))}
                    </tbody>
                  </table>
                  {!showAll && !showAllBids && canShowMore && (
                    <div className="sticky shadow-scroll-blue bg-scroll-gradient w-full bottom-7 m-0"></div>
                  )}
                </div>
              </>
            )}
            {showMorePosition === "content" && (
              <div className="mt-5 mb-6">
                {!isAuctionInactive && showMoreBidsLink}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AuctionBidHistory;
