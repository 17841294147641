"use client";

import useCountDownTimer from "@/hooks/useCountDownTimer";
import { parseDate, parseToDate } from "@/lib/utils";
import { AuctionItem } from "@/types/auction";
import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface BidCountDownProps {
  item: AuctionItem;
  hasEnded: boolean;
  isWinner: boolean;
  isFetching?: boolean;
  bidData: any;
  serverTime: any;
  noCountDownTextColor?: boolean;
}

const HourglassEmpty = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g mask="url(#mask0_150_531)">
      <path
        d="M8 20H16V17C16 15.9 15.6083 14.9583 14.825 14.175C14.0417 13.3917 13.1 13 12 13C10.9 13 9.95833 13.3917 9.175 14.175C8.39167 14.9583 8 15.9 8 17V20ZM12 11C13.1 11 14.0417 10.6083 14.825 9.825C15.6083 9.04167 16 8.1 16 7V4H8V7C8 8.1 8.39167 9.04167 9.175 9.825C9.95833 10.6083 10.9 11 12 11ZM4 22V20H6V17C6 15.9833 6.2375 15.0292 6.7125 14.1375C7.1875 13.2458 7.85 12.5333 8.7 12C7.85 11.4667 7.1875 10.7542 6.7125 9.8625C6.2375 8.97083 6 8.01667 6 7V4H4V2H20V4H18V7C18 8.01667 17.7625 8.97083 17.2875 9.8625C16.8125 10.7542 16.15 11.4667 15.3 12C16.15 12.5333 16.8125 13.2458 17.2875 14.1375C17.7625 15.0292 18 15.9833 18 17V20H20V22H4Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
    </g>
  </svg>
);

const HourglassStrike = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00043 2H16.0004V5.5L13.1604 8.34L14.4104 9.59L18.0004 6.01L17.9904 6H18.0004V0H6.00043V1.17L8.00043 3.17V2Z"
      fill="currentColor"
      fillOpacity="0.7"
    />
    <path
      d="M2.10043 0.0999999L0.69043 1.51L9.59043 10.41L6.00043 14L6.01043 14.01H6.00043V20H18.0004V18.83L20.4904 21.32L21.9004 19.91L2.10043 0.0999999ZM16.0004 18H8.00043V14.5L10.8404 11.66L16.0004 16.83V18Z"
      fill="currentColor"
      fillOpacity="0.7"
    />
  </svg>
);

const BidCountDown: React.FC<BidCountDownProps> = ({
  item,
  hasEnded,
  noCountDownTextColor = false,
  isWinner,
  isFetching = false,
  serverTime,
}) => {
  const { timeRemainingString, isEnding } = useCountDownTimer(
    serverTime,
    item.itemEndTime
  );

  const displayedDateClass = classNames({
    "text-14 self-stretch relative text-lg leading-[150%] font-semibold": true,
    "text-danger": isEnding && !noCountDownTextColor,
  });

  const DisplayedDate = () => {
    return (
      <div className={displayedDateClass} suppressHydrationWarning>
        {timeRemainingString}
      </div>
    );
  };

  const dateOptions: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
  };

  const formattedDate = parseToDate(item.itemEndTime).toLocaleDateString(
    "sv-SE",
    dateOptions
  );

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedTime = parseToDate(item.itemEndTime).toLocaleTimeString(
    "sv-SE",
    timeOptions
  );

  const dateCheck = parseDate(item.itemEndTime);

  const formattedDateTime = isNaN(dateCheck)
    ? ``
    : `${formattedDate} ${formattedTime}`;

  if (isFetching) {
    return (
      <div className="self-stretch relative leading-[150%] opacity-20">
        <Skeleton height={30} baseColor="currentColor" />
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-row items-start justify-start space-x-3 text-sm">
        {hasEnded ? <HourglassStrike /> : <HourglassEmpty />}
        <div className="relative leading-[150%] font-semibold flex text-lg items-center flex-grow h-6 shrink-0">
          <div>
            {hasEnded ? (
              <span>Avslutad {formattedDateTime}</span>
            ) : (
              <DisplayedDate />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidCountDown;
